import React from 'react';
import './Home.css';
import home from '../../assets/home.png';

function Home() {

    return (
        <div className="Home">
            <div className="bg-image">
                <a href="/model">
                    <img src={home} alt="Home" className="img-home"/>
                </a>
            </div>
        </div>
    );
}

export default Home;