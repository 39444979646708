import React, { useState, useEffect } from 'react';
import './LandPart.css';
import { InputText } from 'primereact/inputtext';
import "react-simple-keyboard/build/css/index.css";
import { Dialog } from 'primereact/dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Checkbox } from 'primereact/checkbox';
import 'intl-tel-input/build/css/intlTelInput.css';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { Dropdown } from 'primereact/dropdown';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const axios = require('axios');

const ItemAnswerQuestion = (item) => {
    return (
        <div key={item.question + item.answer} className="qaitem">
            <div className="new-question-item">
                {item.question}
            </div>
            <div className="new-question-item">
                <div className="msg" dangerouslySetInnerHTML={{ __html: "Olá, tudo bem? " + item.answerAi + " Obrigado por comprar com a gente." }} />
                <div className="user-mia">Respondido pela MIA</div>
                <div className="answer-time">{new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}</div>
            </div>
        </div>
    );
}

function LandPart() {
    const [envVariables, setEnvVariables] = useState({
        URLAPI: '',
        URLCRAWLER: '',
        URLCOMPLETATIONSCHAT: '',
        APIKEY: '',
        CONFIRMACAO_CADASTRO: '',
        GPT_VERSION: ''
    });
    const [link, setLink] = useState('');
    const [question, setQuestion] = useState('');
    const [questionObject, setQuestionObject] = useState('');
    const [product, setProduct] = useState('');
    const [visible, setVisible] = useState(false);
    const [visibleLimit, setVisibleLimit] = useState(false);
    const [answerSeller, setAnswerSeller] = useState('');
    const [loading, setLoading] = useState(false);
    const [listQuestionLink, setListQuestionLink] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [celular, setCelular] = useState({ iso2: 'br', dialCode: '55', phone: '' });
    const [tamanhoEquipe, setTamanhoEquipe] = useState('');
    const [aceiteTermo, setAceiteTermo] = useState(false);
    const [ipContact, setIpContact] = useState('');
    const [captcha, setCaptcha] = useState(0);
    const [rand1] = useState(Math.floor(0 + Math.random() * (10 - 0)));
    const [rand2] = useState(Math.floor(0 + Math.random() * (10 - 0)));
    const [errorCaptcha, setErrorCaptcha] = useState(false);
    const [count, setCount] = useState(0);
    const [vendeEmMarketplace, setVendeEmMarketplace] = useState('');
    const [comoConheceu, setComoConheceu] = useState('');
    const options = [
        { name: 'Selecione', value: '0' },
        { name: 'Apenas 1 pessoa', code: '1' },
        { name: 'De 2 a 4 pessoas', code: '2' },
        { name: 'De 5 a 10 pessoas', code: '3' },
        { name: 'Acima de 10 pessoas', code: '4' },
        { name: 'Não tenho equipe exclusiva', code: '5' }
    ];
    const comoConheceuOptions = [
        { name: 'Selecione', value: '' },
        { name: 'Google', value: 'Google' },
        { name: 'LinkedIn', value: 'LinkedIn' },
        { name: 'Facebook', value: 'Facebook' },
        { name: 'Instagram', value: 'Instagram' },
        { name: 'YouTube', value: 'YouTube' },
        { name: 'Indicação de Marketplace', value: 'Indicação de Marketplace' },
        { name: 'Indicação de ERP, plataforma ou agência', value: 'Indicação de ERP, plataforma ou agência' },
        { name: 'Indicação de consultoria', value: 'Indicação de consultoria' },
        { name: 'Indicação de cliente', value: 'Indicação de cliente' },
        { name: 'Eventos', value: 'Eventos' },
        { name: 'Conteúdos', value: 'Conteúdos' },
        { name: 'Outros', value: 'Outros' }
    ];

    useEffect(() => {
        const getEnvVariables = async () => {
            try {
                const response = await fetch('http://164.152.45.74:3366/env');
                const data = await response.json();
                setEnvVariables({
                    URLAPI: data.URLAPI,
                    URLCRAWLER: data.URLCRAWLER,
                    URLCOMPLETATIONSCHAT: data.URLCOMPLETATIONSCHAT,
                    APIKEY: data.APIKEY,
                    CONFIRMACAO_CADASTRO: data.CONFIRMACAO_CADASTRO,
                    GPT_VERSION: data.GPT_VERSION
                });
            } catch (error) {
                console.error('Erro ao obter variáveis de ambiente', error);
            }
        };
    
        const initializeData = async () => {
            await getEnvVariables();
            if (count === 0) {
                getData();
                setCount(count + 1);
            }
        };
    
        initializeData();
    }, [question, count]);

    const { URLAPI,
        URLCRAWLER, 
        URLCOMPLETATIONSCHAT, 
        CONFIRMACAO_CADASTRO,
        APIKEY,
        GPT_VERSION 
    } = envVariables;

    function buscaInfo() {
        setLoading(true);
        axios.get(`${URLCRAWLER}/session?link=` + link)
            .then(function (response) {
                setQuestion('');
                saveProduct(response.data);
                setLoading(false);
                setQuestionObject('');
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
                setLink('');
                setQuestion('');
                alert("Seu link não é válido tente outro.");
            });
    }

    function getAllQuestionByLink(productTemp) {
        axios.get(`${URLAPI}/question/${productTemp._id}/busca`).then(function (response) {
            setListQuestionLink(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function saveProduct(newProduct) {
        axios.post(`${URLAPI}/product/create`, newProduct).then(function (response) {
            setProduct(response.data);
            getAllQuestionByLink(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function getQuestionSeller() {
        await axios.get(`${URLAPI}/question`, { params: { productId: product._id } }).then(function (response) {
            sendToIA(response.data);
        }).catch(function (error) {
            console.log(error);
            sendToIA([]);
        });
    }

    function getPrompt(listAnswerSeller) {
        let promptTemp = "";
        promptTemp = promptTemp + 'Considere a descrição do seguinte produto.' +
            'Sobre o frete, entrega ou infomações de frete e entrega. A: FRETE';
        promptTemp = promptTemp + "[FICHA DO PRODUTO]";
        promptTemp = promptTemp + " titulo: " + product.title + ". ";
        promptTemp = promptTemp + " preço: R$ " + product.price + ". ";
        product.principal.map(item => promptTemp = promptTemp + " " + item + ". ");
        product.other.map(item => promptTemp = promptTemp + " " + item + ". ");
        promptTemp = promptTemp + " descrição: " + product.description + ". ";
        if (listAnswerSeller.length > 0) {
            listAnswerSeller.map(item => promptTemp = promptTemp + " Q:" + item.question + " A:" + item.answerSeller + ". ");
        }
        return promptTemp + " Agora, baseado exclusivamente nas informações acima de forma humanizada, responda a seguinte pergunta do cliente. Caso não seja nada sobre as informações acima A: ERROR \n Q:" + question + " A:";
    }

    function submit(e) {
        setLoading(true);
        e.preventDefault();
        sendToMIA();
    }

    function submitContact(e) {
        getData();
        setErrorCaptcha((rand1 + rand2) !== captcha);
        e.preventDefault();
        if ((rand1 + rand2) === captcha) {
            var contactForm = {
                "nome": name,
                "email": email,
                "empresa": empresa,
                "celular": celular.dialCode + celular.phone,
                "tamanhoEquipe": tamanhoEquipe.name,
                "vendeEmMarketplace": vendeEmMarketplace,
                "comoConheceu": comoConheceu,
                "aceiteTermo": aceiteTermo,
                "ip": ipContact,
                "data": new Date()
            };

            console.log('Dados do formulário:', contactForm);

            fetch(`${URLAPI}/contact/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(contactForm)
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Erro ao enviar dados');
                })
                .then(data => {
                    window.location.replace(`${CONFIRMACAO_CADASTRO}`);
                })
                .catch(error => {
                    console.error('Erro:', error);
                });
        } else {
            alert("Digite a soma corretamente por favor!");
        }
    }

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIpContact(res.data.ip);
    };

    async function sendToMIA() {
        setQuestion('');
        await getQuestionSeller();
    }

    function createListContextGptDTO(context, question) {
        return [
            { role: "system", content: context },
            { role: "user", content: question }
        ];
    }

    function sendToIA(listAnswerSeller) {
        console.log(listQuestionLink.length);
        if (listQuestionLink.length + 1 > 4) {
            modalHandleLimit();
            setLoading(false);
            return;
        }
        const prompt = getPrompt(listAnswerSeller);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${APIKEY}`
        };  

        const data = {
            model: `${GPT_VERSION}`,
            messages: createListContextGptDTO(prompt, question),
            max_tokens: 200,
            temperature: 0,
            top_p: 0.1,
            frequency_penalty: 0,
            presence_penalty: 0
        };
        axios.post(URLCOMPLETATIONSCHAT, data, {
            headers: headers
        }).then(function (response) {
            insertQuestionAnswer(response.data.choices[0].message.content);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function onlySpaces(str) {
        return str.trim().length === 0;
    }

    function insertQuestionAnswer(answer) {
        if (answer.toUpperCase().includes('ERROR') || onlySpaces(answer)) {
            answer = "Essa mensagem será enviada para seu atendente. Eu não tenho segurança para responder.";
        }
        if (answer.toUpperCase().includes('FRETE')) {
            answer = "O valor do frete varia de acordo com o CEP de destino. Você pode calcular o valor e prazo de entrega do seu frete no simulador de entrega, abaixo das opções de pagamento, no lado direito do anúncio.";
        }
        let lastChar = answer.length - 1;
        answer = answer[lastChar] === '.' ? answer : answer + ".";
        const newQuestion = {
            productId: product._id,
            question: question,
            answerAi: answer,
            date: new Date(),
            approved: true,
            answerSeller: '',
            ip: ipContact
        };
        saveQuestion(newQuestion);
    }

    function saveQuestion(newQuestion) {
        axios.post(`${URLAPI}/question/create`, newQuestion).then(function (response) {
            setQuestionObject(response.data);
            getAllQuestionByLink(product);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function likeOrUnlike(object, approved, answerSeller) {
        modalHandle();
        setAnswerSeller('');
        let newObject = {
            _id: object._id,
            productId: object.productId,
            question: object.question,
            answerAi: object.answerAi,
            date: object.date,
            approved: approved,
            answerSeller: answerSeller
        };
        updateQuestion(newObject);
    }

    function updateQuestion(newQuestion) {
        axios.put(`${URLAPI}/question/${newQuestion._id}/update`, newQuestion).then(function (response) {
            setQuestionObject(response.data);
            getAllQuestionByLink(product);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const renderFooter = () => {
        return (
            <div className="button-modal">
                <Button variant="contained" onClick={modalHandle} className="btn-cancel-modal">
                    Cancelar
                </Button>
                <Button variant="contained" className="btn-save-modal" onClick={() => likeOrUnlike(questionObject, false, answerSeller)} autoFocus disabled={answerSeller.length < 5}>
                    Salvar
                </Button>
            </div>
        );
    }

    const renderFooterLimit = () => {
        return (
            <div className="button-modal">
                <Button variant="contained" className="btn-save-modal" onClick={() => modalLimitContact()}>
                    Entrar em contato!
                </Button>
            </div>
        );
    }

    function modalHandle() {
        setVisible(!visible);
    }

    function modalLimitContact() {
        modalHandleLimit();
        document.getElementById('form-contact').scrollIntoView();
    }

    function modalHandleLimit() {
        setVisibleLimit(!visibleLimit);
    }

    const handleChange = (event) => {
        setVendeEmMarketplace(event.target.value);
    };

    return (
        <div className="LandPage">
            <div className='banner-simulador'>
                <Container>
                    <Grid item xs={12} className='text'>
                        Teste a inteligência artificial do predize (MIA) para responder
                        perguntas em anúncios do mercado Livre, e reduza 60% do
                        esforço do seu time e aumente em até 70% suas vendas
                    </Grid>
                </Container>
            </div>

            <div className='banner-data-percentage'>
                <Container>
                    <section className="rd-section-ooo">
                        <div className="rd-row-percentage">

                            <div className="rd-column-data">
                                <div>
                                    <div className="rd-text-percentage">
                                        <p className="text-center">
                                            <strong className="large-text">40%</strong>
                                        </p>
                                        <p className="text-center">
                                            <span className="medium-text">menos esforço no pré</span>
                                        </p>
                                        <p className="text-center">
                                            <span className="medium-text">e pós-venda</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="rd-column-data">
                                <div>
                                    <div className="rd-text-percentage">
                                        <p className="text-center">
                                            <strong className="large-text">60%</strong>
                                        </p>
                                        <p className="text-center">
                                            <span className="medium-text">mais conversão de vendas</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="rd-column-data">
                                <div>
                                    <div className="rd-text-percentage">
                                        <p className="text-center">
                                            <strong className="large-text">50%</strong>
                                        </p>
                                        <p className="text-center">
                                            <span className="medium-text">mais econômico do que um</span>
                                        </p>
                                        <p className="text-center">
                                            <span className="medium-text">humano</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </div>

            <div className="simulador" id="simulador">
                <Container>
                    <Grid item xs={12} className='tutorial'>
                        <div className='text'>
                            <span className='titulo-scanner-input'>
                                <strong>Comece a simular abaixo:</strong>
                            </span>
                            <br />
                            <span className='subtitle-scanner-input'>
                                Copie e cole o link do seu anúncio no campo abaixo e clique em escanear anúncio.
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className='simulador-input' id='simulador'>
                        <InputText value={link} onChange={(e) => setLink(e.target.value)} className="input" placeholder="Cole aqui o link do seu anúncio" required />
                    </Grid>
                    <Grid item xs={12} className="button-action">
                        <Button variant="contained" onClick={() => buscaInfo()} disabled={link === '' || loading}>
                            {loading ?
                                <CircularProgress size="1rem" color="info" /> :
                                "Escanear anúncio"
                            }
                        </Button>
                    </Grid>
                </Container>
            </div>
            {product !== '' &&
                <Container>
                    <Grid item xs={12} className="product-land">
                        <div className='container-img-description'>
                            <h1>{product.title}</h1>
                            <h4>Ficha Técnica:</h4>
                            <div className='title-button-description'>
                                <div className="card-product">
                                    {product.principal.map((item, index) => (
                                        <div>{item}</div>
                                    ))}
                                </div>
                            </div>
                            <div className='image-product'>
                                <img src={product.image} alt={product.title} className="img-product" />
                            </div>
                        </div>
                        {product.title !== 'Relógio Feminino Technos Crystal 2035mqb/5k 38mm Aço Rosê' &&
                            <div className="product-show-msg">
                                <Alert severity="info">Tudo pronto para MIA responder automaticamente. Faça perguntas no campo abaixo!</Alert>
                            </div>
                        }
                        <form onSubmit={submit}>
                            <div className='input-question'>
                                <h4>Pergunte à MIA</h4>
                                <InputText value={question} onChange={(e) => setQuestion(e.target.value)} className="input" required />
                            </div>
                            <div className="button-action">
                                <Button variant="contained" type="submit" disabled={question.length < 5 || loading}>
                                    {loading ?
                                        <CircularProgress size="1rem" color="info" /> :
                                        "Perguntar"
                                    }
                                </Button>
                            </div>
                        </form>
                        <div className='chat-baloon'>
                            {questionObject._id !== undefined ?
                                <div className="answers">
                                    {ItemAnswerQuestion(questionObject)}
                                    <div className="classification">
                                        <div className={["approved ", questionObject.approved ? " likeon " : " likeoff"]}>
                                            <i className="pi pi-thumbs-up" onClick={() => likeOrUnlike(questionObject, true, '')} />
                                        </div>
                                        <div className={["unapproved ", questionObject.approved ? " unlikeoff " : " unlikeon"]}>
                                            <i className="pi pi-thumbs-down" onClick={modalHandle} />
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        {questionObject._id !== undefined ?
                            <div className='disclaimer'>
                                <Alert severity="info">
                                    O aprendizado nessa simulação com seu anúncio
                                    utiliza como base as informações contidas na descrição e
                                    características.Perguntas referentes a frete,
                                    preço, nota fiscal, garantia, estoque e outras variações
                                    do produto podem receber respostas customizadas durante a
                                    ativação da IA em sua loja, ou de serem encaminhadas para
                                    seu time de atendimento. Toda resposta pode ser corrigida
                                    ou melhorada com o que você espera oferecer para seu comprador.
                                    Para saber mais, entre em contato conosco.
                                </Alert>
                            </div>
                            : null}
                        <div className='button'>
                            <a href="#form-contact">
                                Quero falar sobre a MIA
                            </a>
                        </div>
                        <Dialog
                            header={"Pergunta: " + questionObject.question}
                            footer={renderFooter}
                            visible={visible}
                            style={{ width: '90vw' }}
                            onHide={modalHandle}
                            modal>
                            <span className="p-float-label" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                                <InputText value={answerSeller} onChange={(e) => setAnswerSeller(e.target.value)} className="input" required />
                                <label htmlFor="in">Resposta Ideal</label>
                            </span>
                        </Dialog>
                        <Dialog
                            header={"Você atingou o limite de perguntas teste!"}
                            footer={renderFooterLimit}
                            visible={visibleLimit}
                            style={{ width: '90vw' }}
                            onHide={modalHandleLimit}
                            className="dialog-limit"
                            modal>
                            <span className="p-float-label" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                                Você atingiu o limite de perguntas no teste. Mas você pode saber mais sobre a MIA com nosso time!
                            </span>
                        </Dialog>
                    </Grid>
                </Container>
            }

            <div className='banner-sellers'>
                <Container>
                    <section className="rd-section-sellers">
                        <div className="rd-row-sellers">
                            <div className="rd-column-text">
                                <div className="rd-text-sellers">
                                    <p className="centered-paragraph-seller">
                                        <span className="description-text-sellers">A inteligência artificial de atendimento utilizada pelos maiores</span>
                                    </p>
                                    <p className="centered-paragraph-botton-seller">
                                        <span className="description-text-sellers">varejistas do Brasil</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="rd-row-sellers">
                            <div className="rd-column-seller-logo">
                                <div className="rd-image-seller">
                                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/322689/1709921197/$t8jx4tw5xg8" alt="" />
                                </div>
                            </div>
                            <div className="rd-column-seller-logo">
                                <div className="rd-image-seller">
                                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/322689/1709921197/$ewmo7q305gh" alt="" />
                                </div>
                            </div>
                            <div className="rd-column-seller-logo">
                                <div className="rd-image-seller">
                                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/322689/1709921197/$rqni0zriw2h" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </div>
            <div className='banner-sellers'>
                <Container>
                    <section className="rd-section-sellers">
                        <div className="rd-row-sellers">
                            <div className="rd-column-seller-logo">
                                <div className="rd-image-seller">
                                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/322689/1709921197/$1m4h562ie3i" alt="" />
                                </div>
                            </div>
                            <div className="rd-column-seller-logo">
                                <div className="rd-image-seller">
                                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/322689/1709921197/$yeb4fuui66n" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </div>

            <div className="form-mia-contact" id="form-contact">
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={8} lg={8} className="form-mia-text">
                            <div className="title">
                                <strong>Comece a usar uma I.A. de verdade e que custa 50% menos para atender seus clientes</strong>.
                            </div>
                            <div className="sub-title">
                                Sim, o custo por resposta da Mia é mais econômico e vantajoso para você que deseja crescer sua operação online e ter eficiência no atendimento online.
                            </div>
                            <div className="sub-title">
                                Entenda por que:
                            </div>
                            <div className="sub-title">
                                ✔ <strong>Respostas em SEGUNDOS: </strong>respostas enviadas em menos de 1 minuto para garantir ao máximo que cada pergunta se torne uma venda;
                            </div>
                            <div className="sub-title">
                                ✔ <strong>Começa a responder sem treinamento: </strong>são utilizadas as informações do seu anúncio como base para responder de forma humanizada e contextualizada;
                            </div>
                            <div className="sub-title">
                                ✔ <strong>Ela responde enquanto você descansa: </strong>a Mia responde 24h por dia, feriados, fins de semana, e cuida da sua reputação;
                            </div>
                            <div className="sub-title">
                                ✔ <strong>Ela não é um chatbot: </strong>a Mia entende os contextos e intenção das perguntas, respondendo mesmo que a pergunta tenha variações! Ela é 100% interligente.
                            </div>
                            <div className="sub-title">
                                Preencha ao lado para conhecer nossos planos e ativar a IA que vai te ajudar a vender mais enquanto gasta menos!
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} className='form-mia-inputs'>
                            <form onSubmit={submitContact}>
                                <div className="form-header">
                                    <div className="title">
                                        Preencha abaixo para falar com nosso time!
                                    </div>
                                </div>
                                <div className='input-question'>
                                    <label htmlFor="name">Nome*</label>
                                    <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} className="input" required />
                                </div>
                                <div className='input-question'>
                                    <label htmlFor="email">Email*</label>
                                    <InputText id="email" type='email' value={email} onChange={(e) => setEmail(e.target.value)} className="input" required />
                                </div>
                                <div className='input-question'>
                                    <label htmlFor="empresa">Empresa*</label>
                                    <InputText id="empresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} className="input" required />
                                </div>
                                <div className='input-tel'>
                                    <div className='input-question'>
                                        <div className='text-question'>
                                            <label htmlFor="celular">Celular*</label>
                                        </div>
                                        <ReactIntlTelInput value={celular} onChange={setCelular} className="input" required />
                                    </div>
                                </div>
                                <div className='input-question'>
                                    <div className='text-question'>
                                        <label htmlFor="tamanhoEquipe">Tamanho da sua equipe de atendimento*</label>
                                    </div>
                                    <Dropdown optionLabel="name" value={tamanhoEquipe} onChange={(e) => setTamanhoEquipe(e.value)} options={options} />
                                </div>

                                <div className='input-question-radio'>
                                    <div className='text-question-radio'>
                                        <label htmlFor="sellMarketplace">Você vende em marketplace?*</label>
                                    </div>
                                    <div className='input-radio'>
                                        <input
                                            type='radio'
                                            id="sim"
                                            name="vendeEmMarketplace"
                                            value="Sim"
                                            checked={vendeEmMarketplace === 'Sim'}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="sim">Sim</label>
                                    </div>
                                    <div className='input-radio'>
                                        <input
                                            type='radio'
                                            id="nao"
                                            name="vendeEmMarketplace"
                                            value="Não"
                                            checked={vendeEmMarketplace === 'Não'}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="nao">Não</label>
                                    </div>
                                </div>

                                <div className='input-question'>
                                    <div className='text-question'>
                                        <label htmlFor="comoConheceu">Como você conheceu o Predize?*</label>
                                    </div>
                                    <Dropdown optionLabel="name" value={comoConheceu} onChange={(e) => setComoConheceu(e.value)} options={comoConheceuOptions} />
                                </div>                               

                                <div className='input-question-plus'>
                                    <div className='input-question'>
                                        <label htmlFor="captcha">{rand1} + {rand2} = ?*</label>
                                        <InputText id="captcha" type='number' className={'input ' + (errorCaptcha ? 'p-invalid' : 'p-input')} value={captcha} onChange={(e) => setCaptcha(Number(e.target.value))} required />
                                        {errorCaptcha &&
                                            <span className='error'>Digite a soma corretamente</span>
                                        }
                                    </div>
                                </div>
                                <div className='input-check'>
                                    <Checkbox inputId="aceiteTermo" name="aceiteTermo" onChange={(e) => setAceiteTermo(e.target.checked)} checked={aceiteTermo} required />
                                    <label htmlFor="aceiteTermo"> Concordo em receber comunicações do Predize e DB1 Group.</label>
                                </div>
                                <div className='form-footer'>
                                    Ao informar meus dados, estou ciente das diretrizes da <a target='_blank' rel='noreferrer' href='https://predize.com/politica-de-privacidade/'>Política de Privacidade.</a>
                                </div>
                                <Button variant="contained" type="submit">
                                    Enviar
                                </Button>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className='model-box'>
                <Container>
                    <div className='double-column'>
                        <div className='left-column'>
                            <span>A Mia também está no Predize</span>
                            <p>O Predize é a plataforma de atendimento mais completa para quem vende em marketplaces, <strong>pois oferece o maior número de marketplaces integrados + WhatsApp + email.</strong></p>
                            <p>Além disso, traz a Mia para responder automaticamente pré e pós-venda 24h por dia, oferecendo a tecnologia do ChatGPT para potencializar seus resultados!</p>
                        </div>

                        <div className='right-column'>
                            <img className='img-model' src="https://d335luupugsy2.cloudfront.net/cms/files/322689/1712925868/$k1arvto5big" alt="" />
                        </div>
                    </div>
                    <div className="predize-mia">
                        <Grid item xs={12} className="button-conheca">
                            <a href="#form-contact">Conheça o Predize e a MIA em uma demonstração gratuita</a>
                        </Grid>
                    </div>

                </Container>
            </div>
           
            <div className="copyright-predize">
                Predize | 2024
            </div>
        </div>
    );
}

export default LandPart;