import React, { useState, useEffect } from 'react';
import './Model.css';
import { InputText } from 'primereact/inputtext';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import "react-simple-keyboard/build/css/index.css";
import Moment from 'moment';
import { Dialog } from 'primereact/dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const axios = require('axios');

const URLAPI = 'http://164.152.45.74:3366';
const URLCRAWLER = 'http://164.152.45.74:8666';
const URLCOMPLETATIONS = 'https://api.openai.com/v1/completions';
const URLCOMPLETATIONSCHAT = 'https://api.openai.com/v1/chat/completions';

const ItemAnswerQuestion = (item) => {
    return (
        <div key={item.question + item.answer} className="qaitem">
            <div className="new-question-item">
                {item.question}
            </div>
            <div className="new-question-item">
                <div className="msg" dangerouslySetInnerHTML={{ __html: "Olá, tudo bem? " + item.answerAi + " Obrigado por comprar com a gente." }} />
                <div className="user-mia">Respondido pela MIA</div>
                <div className="answer-time">{new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}</div>
            </div>
        </div>
    );
}

function Modelo() {
    const [link, setLink] = useState('');
    const [question, setQuestion] = useState('');
    const [questionObject, setQuestionObject] = useState('');
    const [product, setProduct] = useState('');
    const [visible, setVisible] = useState(false);
    const [answerSeller, setAnswerSeller] = useState('');
    const [loading, setLoading] = useState(false);
    const [listQuestionLink, setListQuestionLink] = useState([]);
    const [count, setCount] = useState(0);
    const [ipContact, setIpContact] = useState('');

    useEffect(() => {
        if (count === 0) {
            getData();
            setCount(count + 1);
        }
    }, [question]);

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIpContact(res.data.ip);
    };

    function buscaInfo() {
        setLoading(true);
        axios.get(`${URLCRAWLER}/session?link=` + link)
            .then(function (response) {
                setLink('');
                setQuestion('');
                saveProduct(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
                setLink('');
                setQuestion('');
                alert("Seu link não é válido tente outro.");
            });
    }

    function getAllQuestionByLink(productTemp) {
        axios.get(`${URLAPI}/question/${productTemp._id}/busca`).then(function (response) {
            setListQuestionLink(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function saveProduct(newProduct) {
        axios.post(`${URLAPI}/product/create`, newProduct).then(function (response) {
            setProduct(response.data);
            getAllQuestionByLink(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function getQuestionSeller() {
        await axios.get(`${URLAPI}/question`, { params: { productId: product._id } }).then(function (response) {
            sendToIA(response.data);
        }).catch(function (error) {
            console.log(error);
            sendToIA([]);
        });
    }

    function getPrompt(listAnswerSeller) {
        let promptTemp = "";
        promptTemp = promptTemp + 'Considere a descrição do seguinte produto.' +
            'Sobre o frete, entrega ou infomações de frete e entrega. A: FRETE';
        promptTemp = promptTemp + "[FICHA DO PRODUTO]";
        promptTemp = promptTemp + " titulo: " + product.title + ". ";
        promptTemp = promptTemp + " preço: R$ " + product.price + ". ";
        product.principal.map(item => promptTemp = promptTemp + " " + item + ". ");
        product.other.map(item => promptTemp = promptTemp + " " + item + ". ");
        promptTemp = promptTemp + " descrição: " + product.description + ". ";
        if (listAnswerSeller.length > 0) {
            listAnswerSeller.map(item => promptTemp = promptTemp + " Q:" + item.question + " A:" + item.answerSeller + ". ");
        }
        return promptTemp + " Agora, baseado exclusivamente nas informações acima de forma humanizada, responda a seguinte pergunta do cliente. Caso não seja nada sobre as informações acima A: ERROR \n Q:" + question + " A:";
    }

    function submit(e) {
        setLoading(true);
        e.preventDefault();
        sendToMIA();
    }

    async function sendToMIA() {
        setQuestion('');
        await getQuestionSeller();
    }

    function createListContextGptDTO(context, question) {
        return [
            { role: "system", content: context },
            { role: "user", content: question }
        ];
    }

    function sendToIA(listAnswerSeller) {
        const prompt = getPrompt(listAnswerSeller);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        };

        const data = {
            model: "gpt-4",
            messages: createListContextGptDTO(prompt, question),
            max_tokens: 200,
            temperature: 0,
            top_p: 0.1,
            frequency_penalty: 0,
            presence_penalty: 0
        };
        axios.post(URLCOMPLETATIONSCHAT, data, {
            headers: headers
        }).then(function (response) {
            insertQuestionAnswer(response.data.choices[0].message.content);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function onlySpaces(str) {
        return str.trim().length === 0;
    }

    function insertQuestionAnswer(answer) {
        if (answer.toUpperCase().includes('ERROR') || onlySpaces(answer)) {
            answer = "Essa mensagem será enviada para seu atendente. Eu não tenho segurança para responder.";
        }
        if (answer.toUpperCase().includes('FRETE')) {
            answer = "O valor do frete varia de acordo com o CEP de destino. Você pode calcular o valor e prazo de entrega do seu frete no simulador de entrega, abaixo das opções de pagamento, no lado direito do anúncio.";
        }
        let lastChar = answer.length - 1;
        answer = answer[lastChar] === '.' ? answer : answer + ".";
        const newQuestion = {
            productId: product._id,
            question: question,
            answerAi: answer,
            date: Moment().toISOString(),
            approved: true,
            answerSeller: '',
            ip: ipContact
        };
        saveQuestion(newQuestion);
    }

    function saveQuestion(newQuestion) {
        axios.post(`${URLAPI}/question/create`, newQuestion).then(function (response) {
            setQuestionObject(response.data);
            getAllQuestionByLink(product);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function likeOrUnlike(object, approved, answerSeller) {
        if (!approved) {
            setAnswerSeller('');
            modalHandle();
        }
        let newObject = {
            _id: object._id,
            productId: object.productId,
            question: object.question,
            answerAi: object.answerAi,
            date: object.date,
            approved: approved,
            answerSeller: answerSeller,
            ip: ipContact
        };
        updateQuestion(newObject);
    }

    function updateQuestion(newQuestion) {
        axios.put(`${URLAPI}/question/${newQuestion._id}/update`, newQuestion).then(function (response) {
            setQuestionObject(response.data);
            getAllQuestionByLink(product);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const renderFooter = () => {
        return (
            <div className="button-modal">
                <Button variant="contained" onClick={modalHandle} className="btn-cancel-modal">
                    Cancelar
                </Button>
                <Button variant="contained" className="btn-save-modal" onClick={() => likeOrUnlike(questionObject, false, answerSeller)} autoFocus disabled={answerSeller.length < 5}>
                    Salvar
                </Button>
            </div>
        );
    }

    function modalHandle() {
        setVisible(!visible);
    }

    return (
        <div className="Modelo">
            <div className="simulador" id="simulador">
                <Grid item xs={12} className='tutorial'>
                    <div className='text'>
                        <span className='titulo-scanner-input'>
                            <strong>Comece a simular abaixo:</strong>
                        </span>
                        <br />
                        <span className='subtitle-scanner-input'>
                            Copie e cole o link do seu anúncio no campo abaixo e clique em escanear anúncio.
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} className='simulador-input' id='simulador'>
                    <InputText value={link} onChange={(e) => setLink(e.target.value)} className="input" placeholder="Cole aqui o link do seu anúncio" required />
                </Grid>
                <Grid item xs={12} className="button-action">
                    <Button variant="contained" onClick={() => buscaInfo()} disabled={link === '' || loading}>
                        {loading ?
                            <CircularProgress size="1rem" color="info" /> :
                            "Escanear anúncio"
                        }
                    </Button>
                </Grid>
            </div>
            {product !== '' &&
                <Container>
                    <Grid item xs={12} className="product-land">
                        {product.title !== 'Relógio Feminino Technos Crystal 2035mqb/5k 38mm Aço Rosê' &&
                            <div className="product-show-msg">
                                <Alert severity="info">Tudo pronto para MIA responder automaticamente. Faça perguntas no campo abaixo!</Alert>
                            </div>
                        }
                        <form onSubmit={submit}>
                            <div className='input-question'>
                                <h4>Pergunte à MIA</h4>
                                <InputText value={question} onChange={(e) => setQuestion(e.target.value)} className="input" required />
                            </div>
                            <div className="button-action">
                                <Button variant="contained" type="submit" disabled={question.length < 5 || loading}>
                                    {loading ?
                                        <CircularProgress size="1rem" color="info" /> :
                                        "Perguntar"
                                    }
                                </Button>
                            </div>
                        </form>
                        <div className='chat-baloon'>
                            {questionObject._id !== undefined ?
                                <div className="answers">
                                    {ItemAnswerQuestion(questionObject)}
                                    <div className="classification">
                                        <div className={["approved ", questionObject.approved ? " likeon " : " likeoff"]}>
                                            <i className="pi pi-thumbs-up" onClick={() => likeOrUnlike(questionObject, true, '')} />
                                        </div>
                                        <div className={["unapproved ", questionObject.approved ? " unlikeoff " : " unlikeon"]}>
                                            <i className="pi pi-thumbs-down" onClick={modalHandle} />
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        {questionObject._id !== undefined ?
                            <div className='disclaimer'>
                                <Alert severity="info">
                                    O aprendizado nessa simulação com seu anúncio
                                    utiliza como base as informações contidas na descrição e
                                    características.Perguntas referentes a frete,
                                    preço, nota fiscal, garantia, estoque e outras variações
                                    do produto podem receber respostas customizadas durante a
                                    ativação da IA em sua loja, ou de serem encaminhadas para
                                    seu time de atendimento. Toda resposta pode ser corrigida
                                    ou melhorada com o que você espera oferecer para seu comprador.
                                    Para saber mais, entre em contato conosco.
                                </Alert>
                            </div>
                            : null}
                        <div className='container-img-description'>
                            <h1>{product.title}</h1>
                            <h4>Ficha Técnica:</h4>
                            <div className='title-button-description'>
                                <div className="card-product">
                                    {product.principal.map((item, index) => (
                                        <div>{item}</div>
                                    ))}
                                </div>
                            </div>
                            <div className='image-product'>
                                <img src={product.image} alt={product.title} className="img-product" />
                            </div>
                        </div>
                        <Dialog
                            header={"Pergunta: " + questionObject.question}
                            footer={renderFooter}
                            visible={visible}
                            style={{ width: '90vw' }}
                            onHide={modalHandle}
                            modal>
                            <span className="p-float-label" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                                <InputText value={answerSeller} onChange={(e) => setAnswerSeller(e.target.value)} className="input" required />
                                <label htmlFor="in">Resposta Ideal</label>
                            </span>
                        </Dialog>
                    </Grid>
                </Container>
            }
        </div>
    );
}

export default Modelo;