import React, { useState, useEffect } from 'react';
import './Relatorio.css';
import { InputText } from 'primereact/inputtext';
import "react-simple-keyboard/build/css/index.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const axios = require('axios');

const URLAPI = 'http://164.152.45.74:3366';

function Relatorio() {
    const [questions, setQuestions] = useState([]);
    const [countVisitDays, setCountVisitDays] = useState([]);
    const [count, setCount] = useState(0);
    const [senha, setSenha] = useState('');
    const [contacts, setContacts] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });

    useEffect(() => {
        if (count === 0) {
            getAllQuestions();
            getCountDays();
            getAllContacts();
            setCount(count + 1);
        }
    }, [questions]);

    function getAllQuestions() {
        axios.get(`${URLAPI}/question/all/agg/`).then(function (response) {
            let question = response.data.map(question => {
                if (question.productId != null 
                    && question.productId !== undefined
                    && question.productId.length > 0) {
                    question.link = question.productId[0].link;
                    question.product = question.productId[0].title;
                }
                question.id = question._id;
                delete question['_id'];
                return question;
            });
            console.log(question);
            setQuestions(question);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getCountDays() {
        axios.get(`${URLAPI}/count_visit/day`).then(function (response) {
            let count = 0;
            let countVistDays = response.data.map(question => {
                question.data = question._id;
                question.id = count;
                count++;
                return question;
            });
            setCountVisitDays(countVistDays);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getAllContacts() {
        axios.get(`${URLAPI}/contact/all/`).then(function (response) {
            let contacts = response.data.map(question => {
                question.id = question._id;
                return question;
            });
            setContacts(contacts);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 150 },
        { field: 'question', headerName: 'Questão', width: 200, minWidth: 150, maxWidth: 700 },
        { field: 'answerAi', headerName: 'Resposta da MIA', width: 200, minWidth: 150, maxWidth: 700 },
        {
          field: 'approved',
          headerName: 'A pergunta tá correta?',
          type: 'boolean', 
          minWidth: 150
        },
        {
          field: 'date',
          headerName: 'Data', 
          width: 200, minWidth: 150, maxWidth: 700,
          valueFormatter: (params) => { return new Date(params.value).toLocaleString();}
        },
        {
            field: 'link',
            headerName: 'Link', 
            width: 100, minWidth: 150, maxWidth: 700
        },
        {
            field: 'product',
            headerName: 'Produto', 
            width: 100, minWidth: 150, maxWidth: 700
        }
    ];

    const columnsDays = [
        { field: 'id', headerName: 'ID', minWidth: 150 },
        { field: 'data', headerName: 'Data', minWidth: 250, valueFormatter: (params) => { return new Date(params.value).toLocaleString();} },
        { field: 'count', headerName: 'Total', width: 200, minWidth: 150, maxWidth: 700 },
    ];


    const columnsContacts = [
        { field: '_id', headerName: 'ID', minWidth: 150 },
        { field: 'nome', headerName: 'Nome', width: 200, minWidth: 150, maxWidth: 700 },
        { field: 'empresa', headerName: 'Empresa', width: 200, minWidth: 150, maxWidth: 700 },
        {
          field: 'celular',
          headerName: 'Celular',
          type: 'boolean', 
          minWidth: 150
        },
        {
          field: 'data',
          headerName: 'Data', 
          width: 200, minWidth: 150, maxWidth: 700,
          valueFormatter: (params) => { return new Date(params.value).toLocaleString();}
        },
        {
            field: 'email',
            headerName: 'Email', 
            width: 100, minWidth: 150, maxWidth: 700
        },
        {
            field: 'tamanhoEquipe',
            headerName: 'Equipe', 
            width: 100, minWidth: 150, maxWidth: 700
        }
    ];

    return (
        <div className="Relatorio">
            <Container>
                <Grid item xs={12}>
                    <h1>Senha</h1>
                </Grid>
                <Grid item xs={12}>
                    <InputText type="password" value={senha} onChange={(e) => setSenha(e.target.value)} className="input" placeholder="Digite a senha" required/>
                </Grid>
                {senha === 'predize@2023' && 
                    <>
                        <Grid item xs={12}>
                            <h1>Relatório de perguntas</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGrid
                                slots={{ toolbar: GridToolbar }}
                                disableColumnResize={true}
                                rows={questions}
                                columns={columns}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                            />;
                        </Grid>
                        <Grid item xs={12}>
                            <h1>Relatório de Visitas</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGrid
                                slots={{ toolbar: GridToolbar }}
                                disableColumnResize={true}
                                rows={countVisitDays}
                                columns={columnsDays}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                            />;
                        </Grid>
                        <Grid item xs={12}>
                            <h1>Relatório de Contatos</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGrid
                                slots={{ toolbar: GridToolbar }}
                                disableColumnResize={true}
                                rows={contacts}
                                columns={columnsContacts}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                            />;
                        </Grid>
                    </>
                }
            </Container>
        </div>
    );
}

export default Relatorio;