import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { TabView, TabPanel } from 'primereact/tabview';
import Model from '../model/Model';
import Marketplaces from '../../assets/marketplaces.png';
import CancelIcon from '../../assets/cancel_icon.png';
import BuyerIcon from '../../assets/buyer_icon.png';
import Shipping from '../../assets/local_shipping.png';
import Anytools from '../../assets/anytools.png';
import NotaIcon from '../../assets/nota_icon.png';
import orderIcon from '../../assets/order_icon.png';
import finalizationIcon from '../../assets/finalization.png';
import trackingIcon from '../../assets/tracking.png';
import saudacaoIcon from '../../assets/saudacao.png';
import NotaIconContext from '../../assets/nota_icon_context.png';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import CancelIconContext from '../../assets/cancel_icon_context.png';
import { Dialog } from 'primereact/dialog';

import './PostSale.css';

const axios = require('axios');

const URLCOMPLETATIONS = 'https://api.openai.com/v1/chat/completions';
const URLAPI = 'http://164.152.45.74:3366';

function PostSale() {
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [answerNow, setAnswerNow] = useState('');
    const [contexts, setContexts] = useState([]);
    const [visible, setVisible] = useState(false);
    const [contextEdit, setContextEdit] = useState('');
    const [responseEdit, setResponseEdit] = useState('');
    const [contextTextEdit, setContextTextEdit] = useState('');
    const [password, setPassword] = useState('');
    const [person, setPerson] = useState('');
    const [showTagCancelation, setShowTagCancelation] = useState(false);

    useEffect(() => {
        if (contexts.length === 0) {
            getContexts();
            getFakePerson();
        }
    }, [answer])
      
    function sendToIA() {
        setLoading(true);
        const question = addQuestion();
        const prompt = getPrompt().concat(question);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        };
        const data = {
            model : "gpt-4o",
            messages: prompt,
            temperature: 0,
            max_tokens: 256,
            top_p: 0.01,
            frequency_penalty: 0,
            presence_penalty: 0
        };
        axios.post(URLCOMPLETATIONS, data, {
            headers: headers
        }).then(function (response) {
            var resposta = response.data.choices[0].message.content;
            addAnswer(resposta, question);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    function getContexts() {
        axios.get(`${URLAPI}/contexts?type=pos`).then(function (response) {
            setContexts(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getDefaultContext() {
        return "\n Qualquer tema que não esteja relacionado no prompt acima, sempre responda: ERROR_RESPOSTA  \n" +
            "\n Em caso de mais de um tema na mesma pergunta sempre formule uma resposta que responda todas questões sem ser repetitivo. Obs: Não remova as quebras de linha e a saudação e assinatura da MIA não precisa ser removida!";
    }

    function getPrompt() {
        const contextFinal = getDefaultContext();
        let contextDinamic = "";
        contexts.forEach(function (context) {
            contextDinamic += "TEMA: " + context.theme + ". CONTEXTO: " + context.context + ". RESPOSTA: " + context.answer + "\n ";
        });
        return  [
                    {
                        "role": "system",
                        "content": [
                            {
                            "text": contextDinamic + contextFinal,
                            "type": "text"
                            }
                        ]
                    }
                ];
    }
    
    function addQuestion() {
        getTagCancelation(answerNow);
        const newList = [...answer];
        const newAnswer = {
            "role": "user",
            "content": [
                {
                    "text": answerNow,
                    "type": "text"
                }
            ]
        };
        newList.push(newAnswer);
        return newList;
    }

    function addAnswer(answerNew, question) {
        if (answerNew === 'ERROR_RESPOSTA') {
            answerNew = 'Olá, eu não tenho contexto para responder isso.\n Vou deixar para nosso time de atendimento responder.'
        }
        if (answerNew === 'ERROR_INSATISFACAO_POS') {
            answerNew = 'Olá, o cliente parece alterado\n Vou deixar para nosso time de atendimento responder.'
        }
        question.filter(x => x.role === 'assistant').forEach(function(x) {
            if (x.content[0].text === answerNew) {
                answerNew = 'Olá, eu já respondi esse contexto anteriormente.\n Vou deixar para nosso time de atendimento responder.'
            }
        });
        const newList = [...question];
        const newAnswer = {
                role: "assistant",
                content: [
                    {
                        text: answerNew.replace('{nome-comprador}', person.name),
                        type: "text"
                    }
                ]
            };
        newList.push(newAnswer);
        setAnswer(newList);
        setAnswerNow('');
    }

    const ItemAnswerQuestion = () => {
        return (
            <div className="qaitem">
                {answer.length > 0 ? answer.map((value, key) => (key % 2) !== 0 ? (
                        <div className="answer-item">
                            {value['content'][0]['text']}
                        </div>
                    ) 
                    : (
                        <div key={value['content'][0]['text']} className="question-item" dangerouslySetInnerHTML={{ __html: value['content'][0]['text'].replace(/\n/g, "<br />") }} />
                    )
                ) : null}
            </div>
        );
    }

    function getImageContext(theme) {
        if (theme === 'Cancelamento') {
            return CancelIconContext;
        }
        if (theme === 'Encerramento') {
            return finalizationIcon;
        }
        if (theme === 'Link de Rastreio') {
            return trackingIcon;
        }
        if (theme === 'Saudação') {
            return saudacaoIcon;
        }
        if (theme === 'Nota fiscal') {
            return NotaIconContext;
        }
        return finalizationIcon;
    }

    function openDialog(context) {
        setVisible(true);
        setContextEdit(context);
        setContextTextEdit(context.context);
        setResponseEdit(context.answer);
    }

    function getTagCancelation(answer) {
        const system = 'Responda se existe risco do comprador concelar a compra somente em casos onde ele deixa claro a intenção de cancelar, respondendo SIM ou NÃO apenas, com base nas seguintes mensagens:';
    
        const newPromptCancelations =[
            {
                "role": "system",
                "content": [
                    {
                        "text": system,
                        "type": "text"
                    }
                ]
            },
            {
                "role": "user",
                "content": [
                    {
                        "text": answer,
                        "type": "text"
                    }
                ]
            },
        ];
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        };
        const data = {
            model : "gpt-4o",
            messages: newPromptCancelations,
            temperature: 0,
            max_tokens: 256,
            top_p: 0.01,
            frequency_penalty: 0,
            presence_penalty: 0
        };
        axios.post(URLCOMPLETATIONS, data, {
            headers: headers
        }).then(function (response) {
            var resposta = response.data.choices[0].message.content;
            if (resposta === 'SIM' && showTagCancelation === false) {
                setShowTagCancelation(true);
            }
        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }


    function saveChangeContext() {
        const newContext = {
            _id: contextEdit._id,
            theme: contextEdit.theme,
            context: contextTextEdit,
            answer: responseEdit,
            data: new Date()
        }
        axios.put(`${URLAPI}/contexts/${contextEdit._id}/update`, newContext).then(function (response) {
            getContexts();
            setVisible(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getFakePerson() {
        axios.get(`https://api.invertexto.com/v1/faker?token=8604%7CrkMqwTE1xnGDyaCQu64f0GbjvkYercRz&locale=pt_BR`).then(function (response) {
            setPerson(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            <Container className='postSale'>
                <TabView>
                    <TabPanel header="Pós-venda" className='padding-zero'>
                        <Container className='padding-zero'>
                            <Grid item xs={12} className='marketplaces'>
                                <img src={Marketplaces} className="img-marketplaces" alt="Logo Marketplaces Pós-venda"/>
                            </Grid>
                            <Grid item xs={12} className='tag-cancelamento'>
                                {showTagCancelation ? 
                                    <div>
                                        <img src={CancelIcon} className="img-cancel" alt="MIA risco de cancelamento"/>
                                        MIA - Risco de Cancelamento
                                    </div>
                                : null}
                            </Grid>
                            <div className='container-chat'>
                                <div className='chat'>
                                    <Grid item xs={12}>
                                        <div className="answers">
                                            {ItemAnswerQuestion()}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className='action-field-button'>
                                        <div className='input-question'>
                                            <InputTextarea placeholder="Digite sua mensagem" value={answerNow} onChange={(e) => setAnswerNow(e.target.value)} className="input-text" required/>
                                        </div>
                                        <div className="button-post-action">
                                            <Button variant="contained" onClick={sendToIA} disabled={answerNow.length < 1}>
                                                {loading ? 
                                                    <CircularProgress size="1rem" color="info" /> :
                                                    "Enviar"
                                                }
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className='context-panel'>
                                        <div className='title-contexts-pos'>Contextos de pós-venda</div>
                                        <div className='list-contexts'>
                                            {contexts.map(context => (
                                                <div key={context.theme} className='context-item' onClick={() => openDialog(context)}>
                                                    <img src={getImageContext(context.theme)} className="img-context" alt={"MIA Context " + context.theme}/>
                                                    {context.theme}
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </div>
                                <div className='info'>
                                    <Grid item xs={12}>
                                        <div className='box-info-post'>
                                            <div className='title'>
                                                <img src={BuyerIcon} className="img-title" alt="Icone Comprador"/>
                                                Dados do Comprador
                                            </div>
                                            <div className='datas'>
                                                <span class='item-data'>
                                                    Nome: <strong>{person.name}</strong>
                                                </span>
                                                <span class='item-data'>
                                                    CPF: <strong>{person.cpf}</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Telefone: <strong>{person.phone_number}</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Cidade: <strong>São Paulo</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='box-info-post'>
                                            <div className='title'>
                                                <img src={Shipping} className="img-title" alt="Icone Entrega"/>
                                                Dados de Entrega
                                            </div>
                                            <div className='datas'>
                                                <span class='item-data'>
                                                    Status Envio: <strong>Entregue</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Endereço: <strong>Avenida Brasil, 456</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Código de rastreio: <strong>114IEKJSJ</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Transportadora: <strong>PostSale Transportadora</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Link de rastreio: <strong>https://lnkrstrio.com/114IEKJSJ</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Data da entrega: <strong>08/08/2024</strong>
                                                </span> 
                                            </div>
                                        </div>
                                        <div className='box-info-post'>
                                            <div className='title'>
                                                <img src={orderIcon} className="img-title" alt="Icone Pedido"/>
                                                Dados de Pedido
                                            </div>
                                            <div className='datas'>
                                                <span class='item-data'>
                                                    Conta: <strong>Mercado Livre</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Data da venda: <strong>08/07/1994</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Status do pedido: <strong>Entregue</strong>
                                                </span>
                                            </div>
                                            <div className='title'>
                                                <img src={NotaIcon} className="img-title" alt="Icone Nota Fiscal"/>
                                                Nota fiscal
                                            </div>
                                            <div className='datas'>
                                                <span class='item-data'>
                                                    Número: <strong>123123SSA244124</strong>
                                                </span>
                                                <span class='item-data'>
                                                    Chave de acesso: <strong>123123SSA244124</strong>
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </div>
                            </div>
                        </Container>
                    </TabPanel>
                    <TabPanel header="Pré-venda">
                        <Model/>
                    </TabPanel>
                </TabView>
            </Container>
            <Dialog header="Editar contexto" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                <h3>{contextEdit.theme}</h3>
                <InputText type='password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <InputTextarea value={contextTextEdit} onChange={(e) => setContextTextEdit(e.target.value)} className="input-text" required/>
                <InputTextarea value={responseEdit} onChange={(e) => setResponseEdit(e.target.value)} className="input-text" required/>
                <Button variant="contained" onClick={saveChangeContext} disabled={contextTextEdit.length < 1 || responseEdit.length < 1 || password !== 'Predize@123'}>Salvar</Button>
            </Dialog>
            <div className='footer'>
                <img src={Anytools} className="img-anytools" alt="Icone Anytools"/> | 2024
            </div>
        </>
    );
}

export default PostSale;
