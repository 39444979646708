import { Outlet, useLocation } from "react-router-dom";
import Header from '../components/Header';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-WWL4XCY15H"; // OUR_TRACKING_ID
const axios = require('axios');
const URLAPI = 'http://164.152.45.74:3366';
let count = 0;

function Root() {
    const location = useLocation();
    // Fired on every route change
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        let ip = res.data.ip;
        var contactForm = {
            "ip" : ip,
            "data" : new Date()
        };
        axios.post(`${URLAPI}/count_visit/create`, contactForm).then().catch(function (error) {
            console.log(error);
        });
    };
    if (count === 0) {
      getData();
      count++;
    }
    useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return (
    <div className="Root">
        <Header/>
        <Outlet />
    </div>
    );
}

export default Root;