import './Header.css';
import logoMIA from '../assets/mia-logo.png';
import logoPredize from '../assets/logo_predize.png';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

function Header() {
    return (
      <div className="headerBack">
        <Container className="Header container">
          <Grid item xs={12}>
            <img src={logoPredize} className="img-predize" alt="Logo Predize"/>
            <img src={logoMIA} className="img-mia" alt="Logo MIA"/>
          </Grid>
        </Container>
      </div>
    );
}

export default Header;