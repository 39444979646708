import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import Model from './pages/model/Model';
import ModelAny from './pages/model-any/ModelAny';
import Land from './pages/landpage/Land';
import LandPart from './pages/landpage-part/LandPart';
import Relatorio from './pages/relatorio/Relatorio';
import Home from './pages/home/Home';
import Root from './pages/Root';
import PostSale from './pages/postsale/PostSale';
import useScript from './hooks/userScript'; 
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-KWGGMJ4'
}

function App() {
  TagManager.initialize(tagManagerArgs)
  useScript("https://d335luupugsy2.cloudfront.net/js/loader-scripts/be412eaa-d01d-40c4-8685-2cc9243a28f0-loader.js");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Root />}>
            <Route index element={<Home />} />
            <Route path="model_any" element={<ModelAny />} />
            <Route path="land" element={<Land />} />
            <Route path="land_parceiro/:parceiro" element={<LandPart />} />
            <Route path="rel" element={<Relatorio />} />
            <Route path="post" element={<PostSale />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
