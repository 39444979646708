import React, { useState, useEffect } from 'react';
import './ModelAny.css';
import { InputText } from 'primereact/inputtext';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import "react-simple-keyboard/build/css/index.css";
import Moment from 'moment';
import { Dialog } from 'primereact/dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';

const axios = require('axios');

const URLAPI = 'http://164.152.45.74:3366';
const URLCRAWLER = 'http://164.152.45.74:8666';
const URLCOMPLETATIONS = 'https://api.openai.com/v1/completions';
const URLCOMPLETATIONSCHAT = 'https://api.openai.com/v1/chat/completions';

const ItemAnswerQuestion = (item) => {
    return (
        <div key={item.question + item.answer} className="qaitem">
            <div className="new-question-item">
                {item.question}
            </div>
            <div className="new-question-item">
                <div className="msg" dangerouslySetInnerHTML={{ __html: "Olá, tudo bem? " + item.answerAi + " Obrigado por comprar com a gente."}}/>
                <div className="user-mia">Respondido pela MIA</div>
                <div className="answer-time">{new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}</div>
            </div>
        </div>
    );
}

function ModeloAny() {
    const [link,             setLink]              = useState({label: 'https://produto.mercadolivre.com.br/MLB-1327633753-bicicleta-aro-29-xks-quadro-aluminio-freio-disco-24-marchas-_JM#position=47&search_layout=grid&type=item&tracking_id=ec54cc9f-fd13-40f0-ad7e-b3ae4dae65b2&c_id=/home/promotions-recommendations/element&c_element_order=5&c_uid=f190de6b-e014-4cb7-8276-6aa3f880ebe7' });
    const [question,         setQuestion]          = useState('');
    const [questionObject,   setQuestionObject]    = useState('');
    const [product,          setProduct]           = useState('');
    const [visible,          setVisible]           = useState(false);
    const [answerSeller,     setAnswerSeller]      = useState('');
    const [loadingScan,          setLoadingScan]      = useState(false);
    const [loadingDesc,          setLoadingDesc]      = useState(false);
    const [loadingQuestion,      setLoadingQuestion]  = useState(false);
    const [listQuestionLink, setListQuestionLink]  = useState([]);
    const [count,            setCount]             = useState(0);
    const [ipContact,        setIpContact]         = useState('');
    const [tabValue,         setTabValue]          = useState('1');

    useEffect(() => {
        if (count === 0) {
            getData();
            setCount(count + 1);
        }
    }, [question]);

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIpContact(res.data.ip);
    };

    function buscaInfo() {
        setLoadingScan(true);
        axios.get(`${URLCRAWLER}/session?link=` + link.label)
        .then(function (response) {
            setLink('');
            setQuestion('');
            saveProduct(response.data);
            setLoadingScan(false);
        })
        .catch(function (error) {
            console.log(error);
            setLoadingScan(false);
            setLink('');
            setQuestion('');
            alert("Seu link não é válido tente outro.");
        });
    }

    function getAllQuestionByLink(productTemp) {
        axios.get(`${URLAPI}/question/${productTemp._id}/busca`).then(function (response) {
            setListQuestionLink(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function saveProduct(newProduct) {
        axios.post(`${URLAPI}/product/create`, newProduct).then(function (response) {
            setProduct(response.data);
            getAllQuestionByLink(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function getQuestionSeller() {
        await axios.get(`${URLAPI}/question`, { params: { productId: product._id}}).then(function (response) {
            sendToIA(response.data);
        }).catch(function (error) {
            console.log(error);
            sendToIA([]);
        });
    }

    function getPrompt(listAnswerSeller) {
        let promptTemp = "";
        promptTemp = promptTemp + 'Considere a descrição do seguinte produto.' +
        'Sobre o frete, entrega ou infomações de frete e entrega. A: FRETE';
        promptTemp = promptTemp + "[FICHA DO PRODUTO]";
        promptTemp = promptTemp + " titulo: " + product.title + ". ";
        promptTemp = promptTemp + " preço: R$ " + product.price + ". ";
        product.principal.map(item => promptTemp = promptTemp + " " + item + ". ");
        product.other.map(item => promptTemp = promptTemp + " " + item + ". ");
        promptTemp = promptTemp + " descrição: " + product.description + ". ";
        if(listAnswerSeller.length > 0){
            listAnswerSeller.map(item => promptTemp = promptTemp + " Q:" + item.question + " A:" + item.answerSeller + ". ");
        }
        return promptTemp + " Agora, baseado exclusivamente nas informações acima de forma humanizada, responda a seguinte pergunta do cliente. Caso não seja nada sobre as informações acima A: ERROR \n Q:" + question + " A:";
    }

    function submit(e){
        setLoadingQuestion(true);
        e.preventDefault();
        sendToMIA();
    }

    async function sendToMIA() {
        setQuestion('');
        await getQuestionSeller();
    }

    function createListContextGptDTO(context, question) {
        return [
            { role: "system", content: context },
            { role: "user", content: question }
        ];
    }

    function sendToIA(listAnswerSeller) {
        const prompt = getPrompt(listAnswerSeller);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        };
        const data = {
            model: "gpt-4",
            messages: createListContextGptDTO(prompt, question),
            max_tokens: 200,
            temperature: 0,
            top_p: 0.1,
            frequency_penalty: 0,
            presence_penalty: 0
        };
        axios.post(URLCOMPLETATIONSCHAT, data, {
            headers: headers
        }).then(function (response) {
            console.log(response);
            insertQuestionAnswer(response.data.choices[0].message.content);
            setLoadingQuestion(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function onlySpaces(str) {
        return str.trim().length === 0;
    }

    function insertQuestionAnswer(answer) {
        if(answer.toUpperCase().includes('ERROR') || onlySpaces(answer)){
            answer = "Essa mensagem será enviada para seu atendente. Eu não tenho segurança para responder.";
        }
        if(answer.toUpperCase().includes('FRETE')){
            answer = "O valor do frete varia de acordo com o CEP de destino. Você pode calcular o valor e prazo de entrega do seu frete no simulador de entrega, abaixo das opções de pagamento, no lado direito do anúncio.";
        }
        let lastChar = answer.length - 1;
        answer = answer[lastChar] === '.' ? answer : answer + ".";
        const newQuestion = {
            productId: product._id,
            question: question,
            answerAi: answer,
            date: Moment().toISOString(),
            approved: true,
            answerSeller: '',
            ip: ipContact
        };
        saveQuestion(newQuestion);
    }

    function saveQuestion(newQuestion) {
        axios.post(`${URLAPI}/question/create`, newQuestion).then(function (response) {
            setQuestionObject(response.data);
            getAllQuestionByLink(product);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function likeOrUnlike(object, approved, answerSeller) {
        if (!approved) {
            setAnswerSeller('');
            modalHandle();
        }
        let newObject = {
            _id: object._id,
            productId: object.productId,
            question: object.question,
            answerAi: object.answerAi,
            date: object.date,
            approved: approved,
            answerSeller: answerSeller,
            ip: ipContact
        };
        updateQuestion(newObject);
    }

    function updateQuestion(newQuestion) {
        axios.put(`${URLAPI}/question/${newQuestion._id}/update`, newQuestion).then(function (response) {
            setQuestionObject(response.data);
            getAllQuestionByLink(product);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const renderFooter = () => {
        return (
            <div className="button-modal">
                <Button variant="contained" onClick={modalHandle} className="btn-cancel-modal">
                    Cancelar
                </Button>
                <Button variant="contained" className="btn-save-modal" onClick={() => likeOrUnlike(questionObject, false, answerSeller)} autoFocus disabled={answerSeller.length < 5}>
                    Salvar
                </Button>
            </div>
        );
    }

    function modalHandle(){
        setVisible(!visible);
    }

    function gerarNovaDescricao() {
        setLoadingDesc(true);
        let prompt = [
            { 'role' : 'system', 'content' : getPromptDescription() }
        ];
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        };
        const data = {
            model : "gpt-3.5-turbo",
            messages: prompt
        };
        axios.post(URLCOMPLETATIONSCHAT, data, {
            headers: headers
        }).then(function (response) {
            var resposta = '';
            if (response.data.choices[0].message.content != null) {
                resposta = response.data.choices[0].message.content;
            }
            var newProduct = product;
            newProduct.description = resposta;
            setProduct(newProduct);
            setLoadingDesc(false);
        }).catch(function (error) {
            setLoadingDesc(false);
            console.log(error);
        });
    }

    function getPromptDescription() {
        return `Assuma o papel de um copywriter profissional com experiência em SEO e e-commerce.
        Siga as instruções abaixo para formular a descrição do anúncio do produto:
        Passo 1: Formule uma descrição consistente para o produto, usando apenas as informações como referência, não invente nenhuma informação nova, e as distribua na forma de um texto comercial.
        Passo 2: O texto deve conter entre 150 e 500 palavras. O tamanho do texto vai depender do volume das informações previamente analisadas. Quanto mais informações, maior deve ser o texto.
        Passo 3: O texto deve conter boas práticas de SEO, como estrutura de cabeçalhos ou estrutura de títulos, palavras-chave, acessibilidade e legibilidade.
        Passo 4: A descrição final elaborada deve conter:
        Descrição do produto dividida em seções;
        Um tópico contendo somente as especificações;
        Por último, uma Call to action.
        Formate usando HTML
        Não gerar código de botões
        Aqui estão as informações do produto que serão utilizadas como referência para executar os comandos acima:
        ${product.title}
        ${getProductAttributes(product)}`; 
    }

    function getProductAttributes(product) {
        let items = '';
        product.principal.map(item => items += item + '\n');
        return items;
    }

    const linksAny = [
        { label: 'https://produto.mercadolivre.com.br/MLB-1327633753-bicicleta-aro-29-xks-quadro-aluminio-freio-disco-24-marchas-_JM#position=47&search_layout=grid&type=item&tracking_id=ec54cc9f-fd13-40f0-ad7e-b3ae4dae65b2&c_id=/home/promotions-recommendations/element&c_element_order=5&c_uid=f190de6b-e014-4cb7-8276-6aa3f880ebe7' },
        { label: 'https://www.mercadolivre.com.br/notebook-samsung-book-celeron-4gb-256gb-ssd-156-w11/p/MLB19692109?pdp_filters=deal:MLB779362-1#searchVariation=MLB19692109&position=3&search_layout=grid&type=product&tracking_id=7b27c5ea-4bca-4f62-8037-3a0c6728ac7a&c_id=/home/promotions-recommendations/element&c_element_order=4&c_uid=c8f4f941-2637-43f1-8368-57c8c9815471' },
        { label: 'https://www.mercadolivre.com.br/caixa-de-som-boombox-3-bluetooth-preta-jbl-bivolt/p/MLB19693584?pdp_filters=deal:MLB779362-1#searchVariation=MLB19693584&position=2&search_layout=grid&type=product&tracking_id=468fd462-f530-4cea-9551-04a9979625d8&c_id=/home/promotions-recommendations/element&c_element_order=2&c_uid=077f7ada-7e28-4a90-8515-825ec25442ee' },
        { label: 'https://www.mercadolivre.com.br/cmera-de-seguranca-im7-full-color-360-speed-dome-intelbras/p/MLB19935262?pdp_filters=deal:MLB779362-1#searchVariation=MLB19935262&position=6&search_layout=grid&type=product&tracking_id=468fd462-f530-4cea-9551-04a9979625d8&c_id=/home/promotions-recommendations/element&c_element_order=8&c_uid=46f7fc07-ba32-4ee2-89dd-4223a27a627b' },
    ];

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className="ModeloAny">
            <div className="simulador" id="simulador">
                <Container>
                    <Grid item xs={12} className='tutorial'>
                        <div className='text'>
                            <span className='titulo-scanner-input'>
                                <strong>Comece a simular abaixo:</strong>
                            </span>
                            <br/>
                            <span className='subtitle-scanner-input'>
                                Copie e cole o link do seu anúncio no campo abaixo e clique em escanear anúncio.
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className='simulador-input' id='simulador'>
                        <Autocomplete
                            className="input"
                            // disablePortal
                            value={link}
                            onChange={(e, value) => {
                                console.log(value);
                                setLink(value);
                            }}
                            id="combo-box-demo"
                            options={linksAny}
                            renderInput={(params) => <TextField onChange={(e) => setLink({ label: e.target.value})} {...params} value={link} className="input" placeholder="Cole aqui o link do seu anúncio" required/>}
                        /> 
                    </Grid>
                    <Grid item xs={12} className="button-action">
                        <Button variant="contained" onClick={() => buscaInfo()} disabled={link === '' || loadingScan}>
                            {loadingScan ? 
                                <CircularProgress size="1rem" color="info" /> :
                                "Escanear anúncio"
                            }
                        </Button>
                    </Grid>
                </Container>
            </div>
            {product !== '' && 
                <Container>
                    <Grid item xs={12} className="product-land">
                        <form onSubmit={submit}>
                            <div className='input-question'>
                                <h4>Pergunte à MIA</h4>
                                <InputText value={question} onChange={(e) => setQuestion(e.target.value)} className="input" required/>
                            </div>
                            <div className="button-action">
                                <Button variant="contained" type="submit" disabled={question.length < 5 || loadingQuestion}>
                                    {loadingQuestion ? 
                                        <CircularProgress size="1rem" color="info" /> :
                                        "Perguntar"
                                    }
                                </Button>
                            </div>
                        </form>
                        <div className='chat-baloon'>
                            {questionObject._id !== undefined ?
                            <div className="answers">
                                {ItemAnswerQuestion(questionObject)}
                                <div className="classification">
                                    <div className={["approved ", questionObject.approved ? " likeon " : " likeoff"]}>
                                        <i className="pi pi-thumbs-up" onClick={() => likeOrUnlike(questionObject, true, '')}/>
                                    </div>
                                    <div className={["unapproved ", questionObject.approved ? " unlikeoff " : " unlikeon"]}>
                                        <i className="pi pi-thumbs-down" onClick={modalHandle}/>
                                    </div>
                                </div>
                            </div>
                            : null}
                        </div>
                        <div className='image-product'>
                            <img src={product.image} alt={product.title} className="img-product"/>
                            <h1>{product.title}</h1>
                        </div>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '0 3rem' }}>
                                <TabList onChange={handleChangeTabValue} aria-label="lab API tabs example">
                                    <Tab label="Ficha Técnica" value="1" />
                                    <Tab label="Descrição" value="2" />
                                </TabList>
                                <TabPanel value="1">
                                    <div className='description-container'>
                                        <div className='description'>
                                            <div className="card-product">
                                                {product.principal.map((item, index) => (
                                                    <div>{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div className='description-container'>
                                        <div className='description' dangerouslySetInnerHTML={{ __html: product.description }} />
                                        <Grid item xs={12} className="button-action">
                                            <Button variant="contained" onClick={() => gerarNovaDescricao()}>
                                                {loadingDesc ? 
                                                    <CircularProgress size="1rem" color="secondary" /> :
                                                    "Enriquecer Anúncio"
                                                }
                                            </Button>
                                        </Grid>
                                    </div>
                                </TabPanel>
                            </Box>
                        </TabContext>
                        {questionObject._id !== undefined ?
                            <div className='disclaimer'>
                                <Alert severity="info">
                                    O aprendizado nessa simulação com seu anúncio 
                                    utiliza como base as informações contidas na descrição e 
                                    características.Perguntas referentes a frete, 
                                    preço, nota fiscal, garantia, estoque e outras variações 
                                    do produto podem receber respostas customizadas durante a 
                                    ativação da IA em sua loja, ou de serem encaminhadas para 
                                    seu time de atendimento. Toda resposta pode ser corrigida 
                                    ou melhorada com o que você espera oferecer para seu comprador. 
                                    Para saber mais, entre em contato conosco.
                                </Alert>
                        </div>
                        : null}
                        <Dialog 
                            header={"Pergunta: " + questionObject.question} 
                            footer={renderFooter} 
                            visible={visible}
                            style={{width: '90vw'}}
                            onHide={modalHandle}
                            modal>
                            <span className="p-float-label" style={{marginTop: '2rem', marginBottom: '1rem'}}>
                                <InputText value={answerSeller} onChange={(e) => setAnswerSeller(e.target.value)} className="input" required/>
                                <label htmlFor="in">Resposta Ideal</label>
                            </span>
                        </Dialog>
                    </Grid>
                </Container>
            }
            <div className="copyright-predize">
                Anytools | 2024
            </div>
        </div>
    );
}

export default ModeloAny;